import {INIT_CASES_PSEUDO} from "./cases/pseudo.cases"
import {INIT_CASES_NAVS} from "./cases/navs.cases"
import {INIT_CASES_MODALS} from "./cases/modals.cases";
import {INIT_SLIDER_SLIDES} from "./cases/slides.cases";

export const INIT_CASES = () => {
  INIT_CASES_PSEUDO();
  INIT_CASES_NAVS();
  INIT_SLIDER_SLIDES();
  INIT_CASES_MODALS();
};
