const SWITCHER_WRAPPER = document.querySelector("[data-switcher-wrapper]");
const SWITCHER_BULLETS = document.querySelectorAll("[data-switcher-bullet]");
const GET_CONTENT_BY_PICTURE_ID = (pictureElement) => document.querySelector("[data-switcher-content='" + pictureElement.dataset.switcherPicture + "']");
const GET_BULLET_BY_PICTURE_ID = (pictureElement) => document.querySelector("[data-switcher-bullet='" + pictureElement.dataset.switcherPicture + "']");

window.rInterval = (callback, delay) => {
  let dateNow = Date.now;
  let requestAnimation = window.requestAnimationFrame;
  let start = dateNow();
  let stop;
  let intervalFunc = () => {
    dateNow() - start < delay || (start += delay, callback());
    stop || requestAnimation(intervalFunc);
  }

  requestAnimation(intervalFunc);
  return {
    clear: function () {
      stop = 1
    }
  };
};

function TIMER(fn, t) {
  let timerObj = window.rInterval(fn, t);

  this.stop = () => {
    if (timerObj) {
      timerObj.clear();
      timerObj = null;
    }
    return this;
  };

  this.start = function () {
    if (!timerObj) {
      this.stop();
      timerObj = window.rInterval(fn, t);
    }
    return this;
  };

  this.reset = function (newTimer = t) {
    t = newTimer;
    return this.stop().start();
  }
}

const HANDLE_SWITCHER = () => {
  const SHOW_PICTURE = document.querySelector("[data-switcher-picture][data-show]");

  if (!SHOW_PICTURE) return;
  const SHOW_ARRAY = [SHOW_PICTURE, GET_CONTENT_BY_PICTURE_ID(SHOW_PICTURE), GET_BULLET_BY_PICTURE_ID(SHOW_PICTURE)];

  if (SHOW_ARRAY.length <= 0) return;
  const NEXT_PICTURE = SHOW_PICTURE.nextElementSibling || document.querySelector("[data-switcher-picture]:first-child");

  if (!NEXT_PICTURE) return;
  const NEXT_ARRAY = [NEXT_PICTURE, GET_CONTENT_BY_PICTURE_ID(NEXT_PICTURE), GET_BULLET_BY_PICTURE_ID(NEXT_PICTURE)];

  if (NEXT_ARRAY.length <= 0) return;
  const HIDDEN_ELEMENTS = document.querySelectorAll("[data-hide]");

  if (HIDDEN_ELEMENTS.length > 0) {
    HIDDEN_ELEMENTS.forEach(elem => elem.removeAttribute("data-hide"));
  }

  if (NEXT_ARRAY.length > 0) {
    SHOW_ARRAY.forEach(element => {
      element.removeAttribute("data-show");
      element.setAttribute("data-hide", "");
    });
  }

  if (NEXT_ARRAY.length > 0) {
    NEXT_ARRAY.forEach(element => element.setAttribute("data-show", ""));
  }
}

export const INIT_IMAGE_SWITCHER = () => {
  const HANDLE_TIMER = new TIMER(() => HANDLE_SWITCHER(), 4000);

  if (SWITCHER_WRAPPER && window.IntersectionObserver) {
    const SWITCHER_SECTION_OBSERVER = new window.IntersectionObserver(([entry]) => {
      if (!entry.isIntersecting) return;
      HANDLE_TIMER.reset();
    }, {rootMargin: `-50% 0px -50% 0px`});

    SWITCHER_SECTION_OBSERVER.observe(SWITCHER_WRAPPER);
  }

  if (SWITCHER_BULLETS.length > 0) {
    SWITCHER_BULLETS.forEach(BULLET => BULLET.addEventListener("click", () => {

      if (BULLET.hasAttribute("data-show")) return

      HANDLE_TIMER.stop();

      const ALL_ACTIVES = document.querySelectorAll("[data-show]");
      const HIDDEN_ELEMENTS = document.querySelectorAll("[data-hide]");
      const BULLET_IMAGE = document.querySelector("[data-switcher-picture='" + BULLET.dataset.switcherBullet + "']");
      const BULLET_CONTENT = document.querySelector("[data-switcher-content='" + BULLET.dataset.switcherBullet + "']");

      if (HIDDEN_ELEMENTS.length > 0) {
        HIDDEN_ELEMENTS.forEach(element => element.removeAttribute("data-hide"));
      }

      ALL_ACTIVES.forEach(element => {
        element.removeAttribute("data-show");
        element.setAttribute("data-hide", "");
      });

      BULLET.setAttribute("data-show", "");
      BULLET_IMAGE.setAttribute("data-show", "");
      BULLET_CONTENT.setAttribute("data-show", "");
    }));
  }
}

