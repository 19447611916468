import {gsap, ScrollToPlugin} from "gsap/src/all"

gsap.registerPlugin(ScrollToPlugin);

const URL_ANCHOR = window.location.hash;

export const INIT_SMOOTH_SCROLL = () => {
  if (!URL_ANCHOR || !document.querySelector(URL_ANCHOR)) return;
  window.addEventListener('load', () => gsap.to(window, {scrollTo: URL_ANCHOR}));
}

