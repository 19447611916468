import {gsap} from "gsap"
import {ScrollTrigger} from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

export const INIT_STICKIES_SCROLL_TRIGGER = () => {
  const STICKIES_SECTION = document.querySelector('[data-stickies-section]'),
    STICKIES_ROW = document.querySelector('[data-stickies-row]'),
    STICKIES_COLS = document.querySelectorAll('[data-stickies-col]')

  if (STICKIES_SECTION) {
    let maxWidth = 0,
      rowOffsetWidth = 0

    const SET_WIDTHS = () => {
      maxWidth = 0
      rowOffsetWidth = STICKIES_ROW.getBoundingClientRect().x * 2

      STICKIES_COLS.forEach((col) => {
        maxWidth += col.offsetWidth
      })

      return {
        maxWidth: maxWidth,
        rowOffsetWidth: rowOffsetWidth
      }
    }

    const MATCH_MEDIA_STICKIES = gsap.matchMedia()

    MATCH_MEDIA_STICKIES.add("(min-width: 576px)", () => {
      const STICKY_TRIGGER = ScrollTrigger
      const WIDTH = document.querySelector('.stickies__holder').getBoundingClientRect().width
      STICKY_TRIGGER.create({
        trigger: STICKIES_SECTION,
        start: 'top top',
        end: () => `+=${Math.abs((SET_WIDTHS().maxWidth - WIDTH) + SET_WIDTHS().rowOffsetWidth)}`,
        scrub: true,
        pin: true,
        anticipatePin: 1,
        invalidateOnRefresh: true,
        animation: gsap.to(STICKIES_ROW, {
          translateX: Math.abs((SET_WIDTHS().maxWidth - WIDTH) + SET_WIDTHS().rowOffsetWidth) * -1,
          ease: "none"
        })
      })
      STICKY_TRIGGER.config({
        limitCallbacks: true,
        ignoreMobileResize: true
      })
    })
  }
}

export const INIT_MARQUEE_SCROLL_TRIGGER = () => {
  const MARQUEE = document.querySelector('[data-marquee]')

  if (!MARQUEE) return
  const MATCH_MEDIA_MARQUEE = gsap.matchMedia()
  MATCH_MEDIA_MARQUEE.add("all", () => {
    const MARQUEE_TRIGGER = ScrollTrigger
    MARQUEE_TRIGGER.create({
      trigger: document.querySelector('[data-marquee]'),
      start: "top bottom",
      end: "bottom top",
      scrub: true,
      invalidateOnRefresh: true,
      animation: gsap.to(document.querySelector('[data-marquee]'), {
        translateX: Math.abs(document.querySelector('[data-marquee]').getBoundingClientRect().width * .5) * -1
      })
    })

    MARQUEE_TRIGGER.config({
      limitCallbacks: true,
      ignoreMobileResize: true
    })
  })
}

export const INIT_SWITCHER_SCROLL_TRIGGER = () => {
  const SWITCHER_WRAPPER = document.querySelector('[data-switcher-wrapper]'),
  SWITCHER_IMAGES_HOLDER = document.querySelector('[data-switcher-images-holder]')

  if (SWITCHER_WRAPPER && SWITCHER_IMAGES_HOLDER) {
    let matchMediaSwitcher = gsap.matchMedia()
    matchMediaSwitcher.add("all", () => {

      const SWITCHER_TRIGGER = ScrollTrigger
      SWITCHER_TRIGGER.create({
        trigger: SWITCHER_WRAPPER,
        start: 'top bottom',
        end: 'center center',
        scrub: true,
        invalidateOnRefresh: true,
        animation: gsap.to(SWITCHER_IMAGES_HOLDER, {
          maxWidth: '100%',
          borderRadius: 0,
          translateZ: 0,
          ease: 'none'
        })
      })

      SWITCHER_TRIGGER.config({
        limitCallbacks: true,
        ignoreMobileResize: true
      })
    })
  }
}

