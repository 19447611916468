const CALC_HEIGHT_FOR_PSEUDO = () => {
  const HAS_FIRST_PICTURE = !!document.querySelector("[data-cases-item]:first-of-type picture");
  const CALCULATED_HEIGHT = Math.round((document.querySelector("[data-cases-item]:first-of-type picture").getBoundingClientRect().height * .5) + 16);
  return HAS_FIRST_PICTURE ? CALCULATED_HEIGHT : 0;
}

export const INIT_CASES_PSEUDO = () => {
  const CASES_PSEUDO_ELEM = document.querySelector("[data-cases-pseudo]");
  if (!CASES_PSEUDO_ELEM) return;

  CASES_PSEUDO_ELEM.style.top = CALC_HEIGHT_FOR_PSEUDO() + "px";
  window.addEventListener("resize", () => CASES_PSEUDO_ELEM.style.top = CALC_HEIGHT_FOR_PSEUDO() + "px");
}
