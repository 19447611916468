import "masonry-layout/masonry";

import {INIT_CASES} from "../components/init-cases";
import {INIT_COUNTING_NUMBERS} from "../components/init-counting-numbers";
import {INIT_IMAGE_SWITCHER} from "../components/init-switcher";
import {INIT_STICKIES_SCROLL_TRIGGER, INIT_SWITCHER_SCROLL_TRIGGER} from "../components/init-scroll-triggers";
import {INIT_SMOOTH_SCROLL} from "../components/init-smooth-scroll";
import {INIT_SEARCH_TYPER} from "../components/search/typer.search";

document.addEventListener("DOMContentLoaded", () => {
  INIT_SMOOTH_SCROLL();
  INIT_CASES();
  INIT_COUNTING_NUMBERS();
  INIT_IMAGE_SWITCHER();
  INIT_STICKIES_SCROLL_TRIGGER();
  INIT_SWITCHER_SCROLL_TRIGGER();
  INIT_SEARCH_TYPER();
  
});
