const TESTIMONIAL_SLIDES = document.querySelectorAll('[data-cases-item]');

export const INIT_SLIDER_SLIDES = () => {

  const OBSERVER_OPTIONS = {
    rootElement: document.querySelector('[data-cases-root]'),
    rootMargin: '0px -50% 0px -50%',
  }

  const OBSERVER = new IntersectionObserver(ENTRIES => {
    ENTRIES.forEach(ENTRY => {

      if (ENTRY.isIntersecting) {
        const ENTRY_ELEMENT = ENTRY.target
        const ACTIVE_SLIDE = document.querySelector('[data-cases-item][data-active]');

        if (ENTRY_ELEMENT !== ACTIVE_SLIDE) {
          ENTRY_ELEMENT.setAttribute('data-active', '');
          ACTIVE_SLIDE.removeAttribute('data-active');
        }

      }
    })
  }, OBSERVER_OPTIONS)


  TESTIMONIAL_SLIDES.forEach(SLIDE => {
    OBSERVER.observe(SLIDE);
  })
}
