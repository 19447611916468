import Typed from "typed.js";


export const INIT_SEARCH_TYPER = () => {

  if (!window.matchMedia("(min-width: 768px)").matches) return;
  let initialized = false;

  const HERO_SEARCH_INPUT = document.querySelector(".hero [data-search-form] [data-search-input]"),
    WRITERS_TEXT_ELEMENT = HERO_SEARCH_INPUT ? document.getElementById('writerScript') : false,
    WRITERS_TEXTS_JSON = WRITERS_TEXT_ELEMENT ? JSON.parse(WRITERS_TEXT_ELEMENT.textContent) : false,
    TYPER_SENTENCES = WRITERS_TEXTS_JSON ? WRITERS_TEXTS_JSON.sentences : false,
    PLACEHOLDER = HERO_SEARCH_INPUT ? HERO_SEARCH_INPUT.placeholder : '',
    TYPER_OPTIONS = {
      strings: TYPER_SENTENCES || [],
      typeSpeed: 70,
      loop: true,
      smartBackspace: false,

      onBegin: () => initialized = true,
    }

  if (!TYPER_SENTENCES || TYPER_SENTENCES.length === 0) return;

  HERO_SEARCH_INPUT.placeholder = '';

  const TYPER = new Typed(HERO_SEARCH_INPUT, TYPER_OPTIONS);

  HERO_SEARCH_INPUT.focus();
  HERO_SEARCH_INPUT.addEventListener('click', () => {
    if (initialized) {
      HERO_SEARCH_INPUT.blur();
      HERO_SEARCH_INPUT.focus();
      HERO_SEARCH_INPUT.placeholder = PLACEHOLDER;
      HERO_SEARCH_INPUT.setAttribute('spellcheck', 'true')
      initialized = false;
      TYPER.destroy();
    }
  })
}
