const CASES_NAVIGATION_BUTTON = document.querySelectorAll("[data-case-button]")

export const INIT_CASES_NAVS = () => {
  if (!CASES_NAVIGATION_BUTTON) return;

  CASES_NAVIGATION_BUTTON.forEach(button => button.addEventListener("click", () => {

    let activeItem = document.querySelector("[data-cases-item][data-active]");
    let firstItem = document.querySelector("[data-cases-item]:first-child");
    let nextItem;

    if (button.hasAttribute("data-next")) {
      const PREV_BUTTON = document.querySelector("[data-case-button][data-prev]");

      if (activeItem.nextElementSibling.hasAttribute("data-cases-item")) {
        nextItem = activeItem.nextElementSibling;
        PREV_BUTTON && PREV_BUTTON.hasAttribute("data-case-button-hidden")
          ? PREV_BUTTON.removeAttribute("data-case-button-hidden")
          : false;
      } else {
        nextItem = firstItem;
        PREV_BUTTON
          ? PREV_BUTTON.setAttribute("data-case-button-hidden", "")
          : false;
      }
    } else if (button.hasAttribute("data-prev")) {
      if (activeItem.previousElementSibling) {
        nextItem = activeItem.previousElementSibling;
        if (activeItem.previousElementSibling === firstItem) {
          nextItem = firstItem;
          button.setAttribute("data-case-button-hidden", "");
        }
      }
    }

    activeItem.removeAttribute("data-active");
    nextItem.setAttribute("data-active", "");

    const CENTER_OFFSET = (window.innerWidth - nextItem.clientWidth) / 2;

    document.querySelector("[data-cases-slider]").scroll({
      left: nextItem.offsetLeft - CENTER_OFFSET,
      top: 0,
      behavior: "smooth"
    });
  }));
}
