import {CountUp} from "../plugins/countUp.min";

const COUNTING_NUMBERS = document.querySelectorAll("[data-number]");
const DURATION_IN_SECONDS = 2;

const START_ANIMATE_NUMBER = (numberItem, DURATION_IN_SECONDS) => {
  let aimValue = parseInt(numberItem.dataset.number);
  const COUNTER = new CountUp(numberItem, aimValue, {
    duration: DURATION_IN_SECONDS,
    startVal: parseInt(numberItem.dataset.from),
    separator: ".",
    useGrouping: true,
    enableScrollSpy: true,
    scrollSpyOnce: true
  });

  if (COUNTER.error) {
    console.error(COUNTER.error)
  }
}

export const INIT_COUNTING_NUMBERS = () => {
  if (COUNTING_NUMBERS.length <= 0) return
  COUNTING_NUMBERS.forEach(numberItem => START_ANIMATE_NUMBER(numberItem, DURATION_IN_SECONDS));
}
